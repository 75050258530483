<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-app>
    <notification-snackbars-wrapper />

    <v-main>
      <v-app-bar app flat class="v-bar--underline" color="background0">
        <router-link :to="{ name: 'IncidentTable' }" style="text-decoration: none; margin-top: 5px;">
          <img src="/static/unstruct-logo-new.png" width="55" height="55"   />
        </router-link>
        <v-spacer />
        <v-btn small color="error--text" :to="{ name: 'status' }" class="mr-10">
          <v-icon left class="mr-3">mdi-domain</v-icon>
          Active IAOs </v-btn>
      </v-app-bar>
      <organization-banner />
      <report-receipt-card v-if="id" />
      <report-submission-card v-else />
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import NotificationSnackbarsWrapper from "@/components/NotificationSnackbarsWrapper.vue"
import ReportSubmissionCard from "@/incident/ReportSubmissionCard.vue"
import ReportReceiptCard from "@/incident/ReportReceiptCard.vue"
import OrganizationBanner from "@/organization/OrganizationBanner.vue"

export default {
  name: "ReportForm",

  props: {
    project: {
      type: String,
      default: null,
    },
  },

  components: {
    ReportSubmissionCard,
    ReportReceiptCard,
    OrganizationBanner,
    NotificationSnackbarsWrapper,
  },
  data() {
    return {}
  },
  computed: {
    ...mapFields("incident", ["selected.id"]),
  },
}
</script>
