<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-card class="mx-auto ma-4" max-width="700" flat outlined>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-icon large color="primary">mdi-domain</v-icon>
          <h2 class="display-2 text--info ml-3 d-inline-block align-middle">IAO Report</h2>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-4" elevation="2">
            <v-card-text>
              <v-icon large color="tips" class="mr-2">mdi-axis-arrow-info</v-icon>
              Thank you for creating the IAO. This page will be populated with IAO
              resources as they are created (if available).
            </v-card-text>

            <v-card-text>
              <v-icon large color="tips" class="mr-2">mdi-frequently-asked-questions</v-icon>
              If you have any questions, please feel free to review the
              <strong>Frequently Asked Questions (FAQ)</strong> document linked below.
            </v-card-text>

            <v-card-text>
              <v-icon large color="tips" class="mr-2">mdi-account-hard-hat</v-icon>
              For further inquiries, reach out to the listed Activity Lead.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="project_faq" class="faq-container">
        <strong>Checkout this project's incident FAQ document:</strong>
        <a :href="project_faq.weblink" target="_blank" class="faq-link">
          {{ project_faq.name }}
          <v-icon large>open_in_new</v-icon>
        </a>
      </div>
      <v-list>
        <v-list-group :value="true" class="event-details-group">
          <template v-slot:activator>
            <v-list-item-title class="title">Incident Details</v-list-item-title>
          </template>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-file-document-edit-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Title</v-list-item-title>
                <v-list-item-subtitle>{{ title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-axis-arrow-info</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Description</v-list-item-title>
                <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-briefcase-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Project</v-list-item-title>
                <v-list-item-subtitle>{{ project.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-alert-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Type</v-list-item-title>
                <v-list-item-subtitle>{{ incident_type.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-flag-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Priority</v-list-item-title>
                <v-list-item-subtitle>{{ incident_priority.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-thermometer-alert</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Severity</v-list-item-title>
                <v-list-item-subtitle>{{ incident_severity.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-eye-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Visibility</v-list-item-title>
                <v-list-item-subtitle>{{ visibility }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-account-hard-hat</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Activity Lead</v-list-item-title>
                <v-list-item-subtitle>
                  <participant :participant="commander" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon large v-if="incident_priority.page_commander" color="success">mdi-phone-ring-outline</v-icon>
                <v-icon large v-else color="tips">mdi-phone-off-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Activity Lead Paged?</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="incident_priority.page_commander"> The commander was paged. </span>
                  <span v-else>The commander was not paged.</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon large v-if="incident_priority.page_liaison" color="success">mdi-phone-ring-outline</v-icon>
                <v-icon large v-else color="tips">mdi-phone-off-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Liaison Paged?</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="incident_priority.page_liaison"> The liaison was paged. </span>
                  <span v-else>The liaison was not paged.</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon large v-if="incident_priority.page_scribe" color="success">mdi-phone-ring-outline</v-icon>
                <v-icon large v-else color="tips">mdi-phone-off-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Scribe Paged?</v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="incident_priority.page_scribe"> The scribe was paged. </span>
                  <span v-else>The scribe was not paged.</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider />

            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Participants</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip-group column>
                    <v-item v-for="participant in participants" :key="participant.id">
                      <participant :participant="participant" />
                    </v-item>
                  </v-chip-group>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
      <v-list>
        <v-list-group :value="true" class="event-resources-group">
          <template v-slot:activator>
            <v-list-item-title class="title">IAO Resources</v-list-item-title>
          </template>

          <template v-if="activeResourcePlugins.ticket">
            <v-list-item v-if="ticket" :href="ticket.weblink" target="_blank">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-ticket-confirmation-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ticket</v-list-item-title>
                <v-list-item-subtitle>{{ ticket.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>Creating IAO ticket...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageTicket">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageTicket">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-ticket</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Please enable the ticket plugin to create a ticket
                  automatically</v-list-item-title
                >
                <v-list-item-subtitle class="incident-resources-subtitle"
                  >Create external tickets in systems like Jira and ServiceNow</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </template>

          <template>
            <v-list-item
              v-if="storycurve_sketch_id"
              :href="
                storycurve_url +
                '/sketch/' +
                storycurve_sketch_id +
                '/explore?timeline=' +
                storycurve_sketch_id
              "
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon large color="tips">mdi-strategy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incident-resources-title"
                  >Timeline Analysis</v-list-item-title
                >
                <v-list-item-subtitle class="incident-resources-subtitle"
                  >Collaborative timeline analysis
                  platform</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else-if="incident_type.storycurve_logging_enabled">
              <v-list-item-content>
                <v-list-item-title>Creating IAO StoryCurve ...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-icon>
                <v-icon large color="tips">mdi-strategy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Please enable the StoryCurve plugin to log automatically
                </v-list-item-title>
                  <v-btn color="secondary" @click="enableStorycurve">
                    <v-icon large class="mr-2">mdi-strategy</v-icon> Enable StoryCurve Logging
                    <v-spacer></v-spacer>
                    <v-icon color="primary">mdi-open-in-new</v-icon>
                  </v-btn>
            </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="activeResourcePlugins.statuspage && statuspage_id">
            <v-list-item
              v-if="statuspage_id"
              :href="unstatus_url"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon large color="tips">mdi-list-status</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Statuspage</v-list-item-title>
                <v-list-item-subtitle
                  >Modern, Entity-Centric IAO Statuspages with IAOs.</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>Posting incident to statuspage ...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageStatusPage">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageStatusPage">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-list-status</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Please enable the statuspage plugin to post automatically</v-list-item-title
                >
                <v-list-item-subtitle
                  >Modern, Entity-Centric IAO Statuspages with IAOs.
                </v-list-item-subtitle
                >
                <v-btn color="secondary" class="mt-2" @click="enablePlugin('statuspage')">
                  <v-icon large class="mr-2">mdi-list-status</v-icon> Enable StatusPage Plugin
                  <v-spacer></v-spacer>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="activeResourcePlugins.conference">
            <v-list-item v-if="conference" :href="conference.weblink" target="_blank">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-video-wireless-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Conference</v-list-item-title>
                <v-list-item-subtitle>{{ conference.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>Creating conference...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageConference">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageConference">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-video-wireless-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Please enable the conference plugin to create a conference
                  automatically</v-list-item-title
                >
                <v-list-item-subtitle
                  >Integrate with systems like Zoom, Microsoft Teams and
                  Google</v-list-item-subtitle
                >
                <v-btn color="secondary" class="mt-2" @click="enablePlugin('conference')">
                  <v-icon large class="mr-2">mdi-video-wireless-outline</v-icon> Enable Conference Plugin
                  <v-spacer></v-spacer>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="activeResourcePlugins.conversation">
            <v-list-item v-if="conversation" :href="conversation.weblink" target="_blank">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-message-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Conversation</v-list-item-title>
                <v-list-item-subtitle>{{ conversation.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>Creating conversation...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageConversation">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageConversation">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-message-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="highlight-message">
                <v-list-item-title>
                  Please enable the conversation plugin to create a conversation
                  automatically</v-list-item-title
                >
                <v-list-item-subtitle
                  >Deep Slack Integration. More R/T systems are WIP</v-list-item-subtitle
                >
                <v-btn color="secondary" class="mt-2" @click="enablePlugin('conversation')">
                  <v-icon large class="mr-2">mdi-message-text-outline</v-icon> Enable Conversation Plugin
                  <v-spacer></v-spacer>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="activeResourcePlugins.storage">
            <v-list-item v-if="storage" :href="storage.weblink" target="_blank">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-cloud-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Storage</v-list-item-title>
                <v-list-item-subtitle>{{ storage.description }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-icon>
                  <v-icon color="primary">open_in_new</v-icon>
                </v-list-item-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-list-item-title>Creating storage...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageStorage">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageStorage">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-cloud-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Please enable the storage plugin to create storage
                  automatically</v-list-item-title
                >
                <v-list-item-subtitle
                  >Incident specific storage for screenshots, logs and more</v-list-item-subtitle
                >
                <v-btn color="secondary" class="mt-2" @click="enablePlugin('storage')">
                  <v-icon large class="mr-2">mdi-cloud-check-outline</v-icon> Enable Storage Plugin
                  <v-spacer></v-spacer>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-if="activeResourcePlugins.document">
            <template v-if="documents.length">
              <v-list-item
                v-for="document in documents"
                :key="document.resource_id"
                :href="document.weblink"
                target="_blank"
              >
                <v-list-item-icon>
                  <v-icon large color="tips">mdi-text-box-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ document.resource_type | deslug }}</v-list-item-title>
                  <v-list-item-subtitle>{{ document.description }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-icon>
                    <v-icon color="primary">open_in_new</v-icon>
                  </v-list-item-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Creating documents...</v-list-item-title>
                  <v-progress-linear indeterminate></v-progress-linear>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider></v-divider>
          </template>
          <template v-else>
            <v-list-item v-if="!showPluginMessageDocument">
              <v-list-item-content>
                <v-list-item-title>Loading...</v-list-item-title>
                <v-progress-linear indeterminate></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showPluginMessageDocument">
              <v-list-item-icon>
                <v-icon large color="tips">mdi-text-box-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >Please enable the document plugin to create documents
                  automatically</v-list-item-title
                >
                <v-list-item-subtitle
                  >Using Google Docs or Confluence? We have you covered!</v-list-item-subtitle
                >
                <v-btn color="secondary" class="mt-2" @click="enablePlugin('document')">
                  <v-icon large class="mr-2">mdi-text-box-check</v-icon> Enable Document Plugin
                  <v-spacer></v-spacer>
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>

      <v-container class="button-container">
        <v-btn color="error--text" depressed @click="resetSelected()">
          <v-icon left>mdi-domain-plus</v-icon>
          Report another IAO
        </v-btn>
      </v-container>
    </v-card-text>
  </v-card>
</template>


<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
import { forEach, find } from "lodash"
import DocumentApi from "@/document/api"
import Participant from "@/incident/Participant.vue"

import PluginApi from "@/plugin/api"

export default {
  name: "ReportReceiptCard",

  components: {
    Participant,
  },
  data() {
    return {
      isSubmitted: false,
      activeResourcePlugins: {
        document: null,
        ticket: null,
        storage: null,
        conversation: null,
        conference: null,
        statuspage: null,
      },
      project_faq: null,
      showPluginMessageTicket: false,
      showPluginMessageStorage: false,
      showPluginMessageConversation: false,
      showPluginMessageConference: false,
      showPluginMessageDocument: false,
      showPluginMessageStatusPage: false,
    }
  },
  created() {
    this.getFAQ()
    PluginApi.getAllInstances({
      itemsPerPage: -1,
      filter: JSON.stringify({
        and: [
          {
            model: "PluginInstance",
            field: "enabled",
            op: "==",
            value: "true",
          },
          {
            model: "Project",
            field: "name",
            op: "==",
            value: this.project.name,
          },
        ],
      }),
    }).then((response) => {
      let data = response.data.items
      let activeResourcePlugins = this.activeResourcePlugins
      forEach(Object.keys(activeResourcePlugins), function (value) {
        activeResourcePlugins[value] = find(data, function (o) {
          return o.plugin.type === value
        })
      })

      if (!this.activeResourcePlugins.conference) {
        setTimeout(() => {
          this.showPluginMessageConference = true
        }, 3000)
      }
      if (!this.activeResourcePlugins.document) {
        setTimeout(() => {
          this.showPluginMessageDocument = true
        }, 3000)
      }
      if (!this.activeResourcePlugins.conversation) {
        setTimeout(() => {
          this.showPluginMessageConversation = true
        }, 3000)
      }
      if (!this.activeResourcePlugins.storage) {
        setTimeout(() => {
          this.showPluginMessageStorage = true
        }, 3000)
      }
      if (!this.activeResourcePlugins.ticket) {
        setTimeout(() => {
          this.showPluginMessageTicket = true
        }, 3000)
      }
      if (!this.activeResourcePlugins.statuspage) {
        setTimeout(() => {
          this.showPluginMessageStatusPage = true
        }, 5000)
      }
      if (!this.statuspage_id) {
        setTimeout(() => {
          this.showPluginMessageStatusPage = true
        }, 5000)
      }
    })
  },

  computed: {
    ...mapFields("incident", [
      "selected.incident_priority",
      "selected.incident_severity",
      "selected.incident_type",
      "selected.commander",
      "selected.participants",
      "selected.title",
      "selected.tags",
      "selected.description",
      "selected.conversation",
      "selected.conference",
      "selected.visibility",
      "selected.storage",
      "selected.documents",
      "selected.project",
      "selected.loading",
      "selected.ticket",
      "selected.id",
      "selected.storycurve_sketch_id",
      "selected.storycurve_url",
      "selected.unstatus_url",
      "selected.statuspage_id",
    ]),
  },

  methods: {
    enableStorycurve() {
      this.$router.push({
        name: "IncidentTypeTable",
        query: { project: 'default' }
      })
    },
    enablePlugin(pluginType) {
      this.$router.push({
        name: "PluginTable",
        params: { pluginType: pluginType },
        query: { project: 'default' }
      })
    },
    getFAQ() {
      DocumentApi.getAll({
        filter: JSON.stringify({
          and: [
            {
              field: "resource_type",
              op: "==",
              value: "dispatch-incident-reference-faq-document",
            },
            {
              model: "Project",
              field: "name",
              op: "==",
              value: this.project.name,
            },
          ],
        }),
      }).then((response) => {
        if (response.data && response.data.items.length) {
          this.project_faq = response.data.items[0]
        }
      })
    },
    ...mapActions("incident", ["report", "get", "resetSelected"]),
  },
}
</script>

<style>
.faq-container {
  background-color: #1565C0;
  color: white;
  padding: 15px;
  border-radius: 5px;
}

.event-details-group,
.event-resources-group {
  margin-bottom: 16px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
</style>
